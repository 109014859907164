import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Message from 'components/content/Message';
export const _frontmatter = {
  "path": "/developer/typescript-enums-vs-string-literals",
  "date": "2021-10-12",
  "title": "TypeScript Enums vs String Literals",
  "author": "admin",
  "tags": ["development", "javascript", "typesript"],
  "featuredImage": "feature.jpg",
  "excerpt": "Enums help to define a set of named constants. Using enums can make it easier to document intent, or create a set of distinct cases."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <p>{`Enums are one of the few features TypeScript has which is not a type-level extension of JavaScript.`}</p>
    <Message type="warn" title="" content="Enums are one of the few features TypeScript has which is not a type-level extension of JavaScript." mdxType="Message" />
    <p>{`TypeScript 2.4 implemented one of the most requested features: string enums, or, to be more precise, `}<em parentName="p">{`enums with string-valued members`}</em>{`.`}</p>
    <h2>{`Prefer union types over string enums`}</h2>
    <p>{`String enums are named types, That means, even if values are valid and compatible, you can’t pass them to a function or object where you expect a string enum. See this example`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`enum User {
  Admin = 'Admin',
  Client = 'Client',
}

function login(user: User) {
  API.login();
}

login('Admin'); // This is not allowed!
login(User.Admin); // You have to be explicit!
`}</code></pre>
    <p>{`Using union type gives you something that works similarly and is much more aligned with TypeScript.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`type User = 'Admin' | 'Client';

function login(user: User) {
  API.login();
}

login('Admin');
`}</code></pre>
    <p>{`The benefits of using a string literal are:`}</p>
    <ul>
      <li parentName="ul">{`The transpiled code is much smaller compared to an enum (see below)`}</li>
      <li parentName="ul">{`The same benefits you get from enums are valid for string literals: Type-safety, auto-complete, predictable behaviour`}</li>
      <li parentName="ul">{`You don’t have to export the enum declaration if it is used in other files.`}</li>
    </ul>
    <p>{`The code emitted by the TypeScript compiler for an enum:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`'use strict';
var User;
(function (User) {
  User['Admin'] = 'Admin';
  User['Client'] = 'Client';
})(User || (User = {}));
`}</code></pre>
    <h2>{`Object const for better naming`}</h2>
    <p>{`If you still want to write your code enum-style, with an object and a named identifier, a const object might just give you the desired behaviour and is much closer to JavaScript.`}</p>
    <p>{`If the values are harder to read or makes no sense, you can use const objects to give meaningful references.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`const User {
  ADMIN: "Admin",
  CLIENT: "Client",
} as const;

function login(user: User) {
  API.login()
};

login(User.Admin);
`}</code></pre>
    <h2>{`When to use enums`}</h2>
    <p>{`For scenarios where a reference makes sense rather than a value, and it’s used for conditional logic, an enum can be used.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-ts"
      }}>{`enum LogLevel {
  ERROR,
  WARN,
  INFO,
  DEBUG,
}

/**
 * This is equivalent to:
 * type LogLevelStrings = 'ERROR' | 'WARN' | 'INFO' | 'DEBUG';
 */
type LogLevelStrings = keyof typeof LogLevel;

function printImportant(key: LogLevelStrings, message: string) {
  const num = LogLevel[key];
  if (num <= LogLevel.WARN) {
    console.log('Log level key is:', key);
    console.log('Log level value is:', num);
    console.log('Log level message is:', message);
  }
}

printImportant('ERROR', 'This is a message');
`}</code></pre>
    <h2>{`Summary`}</h2>
    <ol>
      <li parentName="ol">{`Use `}<em parentName="li">{`string literal`}</em>{` over `}<em parentName="li">{`string enums`}</em>{` all the times`}</li>
      <li parentName="ol">{`If the values in string literals are harder to read or makes no sense, you can use const objects to give meaningful references.`}</li>
      <li parentName="ol">{`For scenarios where a reference makes sense rather than a value, and it’s used for conditional logic, an `}<em parentName="li">{`enum`}</em>{` can be used.`}</li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      